<template>
  <MDBInput
    inputGroup
    :formOutline="false"
    v-model="searchString"
    aria-describedby="search-addon"
    aria-label="Search"
    :placeholder="searchLabel || 'Search'"
    @keydown.enter="handleSearch"
  >
    <span
      class="input-group-text border-start-0"
      id="search-addon"
      @click="handleSearch"
    >
      <em class="fas fa-search" />
    </span>
  </MDBInput>
</template>

<script>
import { MDBInput } from "mdb-vue-ui-kit";
import axios from "axios";
import { ref, computed } from "vue";
import { isVVV } from "@/helpers/siteIdentifier";
export default {
  components: {
    MDBInput,
  },
  props: ["searchType", "searchLabel"],
  emits: ["searchResult", "isLoading"],
  setup(props, { emit }) {
    const searchString = ref("");
    const assetData = ref("");
    const handleSearch = () => {
      emit("isLoading", true);
      emit("searchResult", []);
      axios
        .get(getUrl.value, {
          PageNumber: 1,
          params: { SearchString: searchString.value },
        })
        .then(function (response) {
          assetData.value = response.data;
          emit("searchResult", assetData.value);
          emit("isLoading", false);
        }).catch(function (error) {
					console.log(`Searching ${props.searchType} error: `, error)
          emit("isLoading", false);
				});
    };

    const getUrl = computed(() => {
      const currentDashboard = isVVV() ? 3 : 2;
      if (props.searchType == "assets") {
        return "api/assets/List";
      } else if (props.searchType == "assetLabel") {
        return "api/assetlabels/List";
      } else if (props.searchType == "userManagement") {
        return `api/users?currentDashboard=${currentDashboard}`;
      } else if (props.searchType == "notifManagement") {
        return "api/creatorvideoupdates/list";
      }

      return "";
    });

    return {
      getUrl,
      handleSearch,
      searchString,
      assetData,
    };
  },
};
</script>

<style></style>
