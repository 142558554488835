<template>
  <LayoutNext>
    <template #page-title>
      Assets
    </template>
    <MDBCard class="shadow-0 card-container">
      <MDBCardHeader class="d-md-flex d-block justify-content-end py-3 px-0">
        <div class="d-flex justify-content-end">
          <Search :searchType="'assets'" @searchResult="assetData = $event" searchLabel="Search Asset"
            @isLoading="isLoading = $event" />
        </div>
      </MDBCardHeader>
      <MDBCardBody class="d-flex flex-column p-0">
        <div class="overflow-auto mt-3" id="table-container">
          <MDBTable hover class="align-middle mb-0">
            <thead class="table-light">
              <tr>
                <th class="sticky-top" v-for="(item, i) in tableHeaders" :key="i" scope="col">
                  {{ item.header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-if="isLoading">
                <tr>
                  <td colspan="2" class="text-center">
                    <div class="image-container d-flex justify-content-center align-items-center">
                      <div class="loader"></div>
                      <img src="@/assets/cs-loader.png" alt="Loading image">
                    </div>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr v-if="assetList.data.length === 0">
                  <td colspan="2" class="text-center py-3">No asset found.</td>
                </tr>
                <tr v-for="(item, i) in assetList.data" :key="i">
                  <td>{{ item.assetId }}</td>
                  <td v-if="item.title === ''"><i>(No Title Specified)</i></td>
                  <td v-else>{{ item.title }}</td>
                </tr>
              </template>
            </tbody>
          </MDBTable>
        </div>
        <div class="overflow-auto">
          <Paginate :pageType="'assets'" @paginationData="assetList = $event" />
        </div>
      </MDBCardBody>
      <MDBToast v-model="toastError" id="basic-danger-example" :delay="2000" autohide position="top-right" appendToBody
        stacking width="350px" color="danger" text="white">
        <template #title>
          {{ toastMessage }}
        </template>
      </MDBToast>
    </MDBCard>
  </LayoutNext>
</template>

<script setup>
import LayoutNext from "@/views/v3/LayoutNext.vue";
import Search from "@/components/CSDSearch.vue";
import Paginate from "@/components/Pagination/CSDPagination.vue";
import { computed, onBeforeMount, ref, watch } from "vue";
import axios from "axios";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { MDBCard, MDBCardHeader, MDBCardBody, MDBTable, MDBToast } from "mdb-vue-ui-kit";
import { useTitle } from "@vueuse/core";

useTitle("Assets | Creator Shield");

const assetData = ref({
  data: [],
  pagination: {},
});

const toastError = ref(false);
const toastMessage = ref("");

dayjs.extend(customParseFormat);
const tableHeaders = computed(() => [
  {
    header: "Asset Id",
  },
  {
    header: "Title",
  },
]);

const assetList = ref({
  data: [],
  pagination: {},
});

watch(assetData, (newAssetData) => {
  assetList.value = newAssetData;
});

const isLoading = ref(false);

onBeforeMount(async () => {
  isLoading.value = true;
  await axios
    .get("api/assets/List")
    .then(function (response) {
      isLoading.value = false;
      assetList.value = response.data;
    })
    .catch(function (error) {
      isLoading.value = false;
      toastError.value = true;
      toastMessage.value = "An error occurred during request." + error;
    });
});
</script>

<style scoped>
.table-dark {
  --mdb-table-bg: var(--accent);
  border-color: var(--accent);
}

th {
  text-transform: uppercase;
}

tr {
  cursor: default;
}

.card-container {
  flex: 1 1 auto;
  overflow: auto;
}

#table-container {
  flex: 1 1 auto;
  height: 55vh;
}

.image-container {
  position: relative;
  display: inline-block;
}

.image-container .loader {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 40px;
  height: 40px;
  border: 3px solid rgba(0, 0, 0, 0.1);
  border-top-color: rgb(153, 153, 153);
  border-radius: 50%;
  animation: BorderSpin 1s linear infinite;
}

.image-container img {
  display: block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
}

@keyframes BorderSpin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
